import React from 'react';
import {
    IonContent,
    IonImg,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonSkeletonText,
    IonLabel,
    IonThumbnail,
    IonItem,
    IonSlides,
    IonSlide
} from "@ionic/react";

import {useLocation} from "react-router-dom";
import {
    getStoreProduct,
    getStoreProducts,
    prepareProductAddons,
    ProductType
} from '../services/Api';
import './ProductContainer.css';

interface ContainerProps {
    category: string;
    id: string;
}

const ProductContainer: React.FC<ContainerProps> = ({category, id}) => {
    const location = useLocation();

    const [imageLoaded, setImageLoaded] = React.useState(false);
    const [addons, setAddons] = React.useState([]);
    const [upsells, setUpsells] = React.useState([]);
    const [product, setProduct] = React.useState({
        id: 0,
        slug: 'string',
        name: 'string',
        price: 'string',
        short_description: 'string',
        images: [{
            src: ''
        }],
        upsell_ids: [0],
        addons: []
    } as ProductType);

    React.useEffect(() => {
        getStoreProduct(id).then((data: React.SetStateAction<ProductType>) => {
            setProduct(data)
        });
    }, [id]);

    React.useEffect(() => {
        setUpsells([]);
        getStoreProducts(product.upsell_ids).then((data: React.SetStateAction<never[]>) => setUpsells(data));
    }, [product.upsell_ids]);

    React.useEffect(() => {
        setAddons(prepareProductAddons(product.addons) as any);
    }, [product.addons]);

    const slideOpts = {
        speed: 400,
    };

    return (
        <IonContent fullscreen>
            {product.id && product.id.toString() === id ? (
                <>
                    <IonCard>
                        <IonImg class='product-img' src={product.images[0].src}
                                onIonImgDidLoad={() => setImageLoaded(true)}/>
                        {!imageLoaded ?
                            <IonSkeletonText class='product-img' animated style={{width: '400px', height: '400px'}}/>
                            : null
                        }
                        <IonCardHeader>
                            <IonCardTitle>{product.name}</IonCardTitle>
                            <IonCardSubtitle>{product.price}€</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <p dangerouslySetInnerHTML={{__html: product.short_description}}></p>
                            <div style={{marginTop: addons.length ? '20px': 0}}>
                            {addons.length ? addons.map(addon => {
                                    return (
                                        <p key={addon["id"]} style={{fontSize: '11px'}}>
                                            <strong>{addon["label"]}</strong>: <span style={{textTransform: 'lowercase'}}>{(addon["options"] as string[]).join(', ')}</span>
                                            {addon["label"] === 'Extras' ? <span style={{fontStyle: 'italic', opacity: 0.65}}> (consultar suplemento en el precio)</span> : ''}
                                        </p>
                                    );
                                })
                                : null}
                            </div>
                            <p style={{opacity: 0.75, fontSize: '11px', fontStyle: 'italic', marginTop: '10px'}}>Suplemento por consumición en terraza: 0,25€ por producto</p>
                        </IonCardContent>
                    </IonCard>
                    {upsells.length && upsells.length < 5 ?
                        <IonCard>
                            <IonCardSubtitle className={'ion-text-center ion-margin-top ion-text-uppercase'}>Productos recomendados</IonCardSubtitle>
                            <IonCardContent>
                                <IonSlides pager={true} options={slideOpts}>
                                    {
                                        upsells.map(upsell => {
                                            return (
                                                <IonSlide class="ion-margin-bottom" key={upsell["id"]}>
                                                    <IonItem class="ion-margin-bottom" lines={'none'}
                                                             disabled={upsell['stock_status'] === 'outofstock'}
                                                             routerLink={location.pathname.substring(0, location.pathname.lastIndexOf("/")) + "/" + upsell['id']}
                                                             routerDirection="forward"
                                                             detail={false}
                                                             key={upsell['id']}>
                                                        <IonThumbnail class="ion-margin-end">
                                                            <img src={upsell['images'][0]['src']} alt={upsell['name']}/>
                                                        </IonThumbnail>
                                                        <IonLabel class={'ion-text-wrap'}>
                                                            <h2>{upsell['name']}</h2>
                                                            {upsell['stock_status'] === 'outofstock' ?
                                                                <p>Agotado</p>
                                                                :
                                                                <p>{upsell['price']}€</p>
                                                            }
                                                        </IonLabel>
                                                    </IonItem>
                                                </IonSlide>
                                            );
                                        })
                                    }
                                </IonSlides>
                            </IonCardContent>
                        </IonCard>
                        : null}
                </>
            ) : (
                <>
                    <IonCard>
                        <IonSkeletonText class='product-img' animated style={{width: '400px', height: '400px'}}/>
                        <IonCardHeader>
                            <IonCardTitle>
                                <IonSkeletonText animated style={{width: '50%', height: '25px'}}/>
                            </IonCardTitle>
                            <IonCardSubtitle>
                                <IonSkeletonText animated style={{width: '10%'}}/>
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonSkeletonText animated style={{width: '95%'}}/>
                            <IonSkeletonText animated style={{width: '90%'}}/>
                            <IonSkeletonText animated style={{width: '92%'}}/>
                            <IonSkeletonText animated style={{width: '90%'}}/>
                            <IonSkeletonText animated style={{width: '50%'}}/>
                        </IonCardContent>
                    </IonCard>
                </>
            )}
        </IonContent>
    );
};

export default ProductContainer;
