import {env} from "../config/config";

const ApiLibrary = require('./ApiLibrary').default;
const StoreApi = new ApiLibrary({url: env.STORE_API_URL});
const MenuApi = new ApiLibrary({url: env.MENU_API_URL});

export interface ProductType {
    id: number;
    slug: string;
    name: string;
    price: string;
    images: { src: string }[];
    short_description: string;
    upsell_ids: number[];
    addons: ProductAddonType[];
}

export interface ProductAddonType {
    id: string;
    group_id: string;
    type: string;
    label: string;
    description: string;
    depend: string;
    options: ProductAddonOptionType;
    required: string;
    sold_individually: string;
    step: string;
    priority: string;
    reg_date: string;
    del: string;
    operator: string;
    depend_variations: string;
    required_all_options: string;
    collapsed: string;
    change_featured_image: string;
    calculate_quantity_sum: string;
    first_options_free: string;
    max_item_selected: string;
    max_input_values_amount: string;
    min_input_values_amount: string;
    minimum_product_quantity: string;
}

export interface ProductAddonOptionType {
    label: string[];
    description: string[];
    price: string[];
    type: string[];
    default: string[];
}

export interface CartItemAddonType {
    id: number;
    label: string;
    type: string;
    options: string[] | { label: string; selected: boolean }[];
    selected?: string;
}

export const getStoreCategoryProducts = (category: number) => {
    return StoreApi.get('products', {
        category,
        order: 'asc',
        per_page: 50,
        status: 'publish'
    }).then((result: { data: any; }) => result.data);
}

export const getStoreProduct = (id: string) => {
    return StoreApi.get(`products/${id}`).then((result: { data: any; }) => result.data);
};

export const getStoreProductBySlug = (slug: string) => {
    return StoreApi.get('products', {
        slug,
        order: 'asc',
        per_page: 1,
        status: 'publish',
    }).then((result: { data: any }) => result.data);
};

export const getStoreProducts = (include: number[]) => {
    return StoreApi.get('products', {
        include,
        order: 'asc',
        per_page: 50,
        status: 'publish'
    }).then((result: { data: any; }) => result.data);
}

export const prepareProductAddons = (addons: ProductAddonType[]) => {
    const addonsList: CartItemAddonType[] = [];

    addons.forEach((addon: ProductAddonType) => {
        const {id, label, type, options} = addon;
        addonsList.push({id: +id, label, type, options: options.label});
    })

    addonsList.sort((a, b) => (a.id > b.id) ? 1 : -1);
    return addonsList;
}

export const getStoreFeaturedProducts = () => {
    return StoreApi.get('products', {
        featured: true,
        order: 'asc',
        per_page: 50,
        status: 'publish',
    }).then((result: { data: any; }) => result.data);
}

export const getMenuDaily = () => {
    return MenuApi.get('dia').then((result: { data: any; }) => result.data);
}
