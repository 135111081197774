import Menu from './components/Menu';
// import Home from './pages/Home';
import Category from './pages/Category';
import Product from './pages/Product';
import MenuPage from './pages/MenuPage';
import Featured from './pages/Featured';
import React from 'react';
import {IonApp, IonRouterOutlet, IonSplitPane} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';
import {firebaseConfig} from "./config/config";
import * as firebase from 'firebase/app';
import 'firebase/analytics';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import 'animate.css';

/* Theme variables */
import './theme/variables.css';

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const App: React.FC = () => {

    return (
        <IonApp>
            <IonReactRouter>
                <IonSplitPane contentId="main">
                    <Menu/>
                    <IonRouterOutlet id="main">
                        {/*<Route path="/" component={Home} exact/>*/}
                        <Route path="/Menu" component={MenuPage} exact/>
                        <Route path="/Destacados" component={Featured} exact/>
                        <Route path="/Carta/:category" component={Category} exact/>
                        <Route path="/Carta/:category/:id" component={Product} exact/>
                        <Redirect exact from="/" to="/Destacados" />
                    </IonRouterOutlet>
                </IonSplitPane>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
