import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';

import React from 'react';
import {useLocation} from 'react-router-dom';
import { medalOutline } from 'ionicons/icons';
import {categories} from "../config/categories";
import './Menu.css';

interface AppPage {
    url: string;
    iosIcon: string;
    title: string;
}

const appPages: AppPage[] = [
    {
        title: 'Destacados',
        url: '/Destacados',
        iosIcon: medalOutline,
    }
];

const Menu: React.FC = () => {
    const location = useLocation();

    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <IonList id="inbox-list">
                    <IonListHeader>Redburger</IonListHeader>
                    <IonNote>Hamburguesería</IonNote>
                    <hr/>
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem className={location.pathname === appPage.url ? 'selected' : ''}
                                         routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                    <IonIcon slot="start" icon={appPage.iosIcon}/>
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                    {/*<IonMenuToggle autoHide={false}>
                        <IonItem href={'https://redburger.es'} target={'_system'} lines="none" detail={false}>
                            <IonIcon slot="start" icon={cartOutline}/>
                            <IonLabel>A domicilio</IonLabel>
                        </IonItem>
                    </IonMenuToggle>*/}
                </IonList>

                <IonList id="labels-list">
                    <IonListHeader>Carta</IonListHeader>
                    {categories.map((category) => {
                        const iconSource = `assets/icons/${category.name}.svg`
                        return (
                            <IonMenuToggle key={category.id} autoHide={false}>
                                <IonItem className={location.pathname.includes(category.url) ? 'selected' : ''}
                                         routerLink={category.url} routerDirection="none" lines="none"
                                         detail={false}>
                                    <IonIcon slot="start" src={iconSource}/>
                                    <IonLabel>{category.name}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
