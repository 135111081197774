import {IonButtons, IonContent, IonHeader, IonBackButton, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import React from 'react';
import {useParams} from 'react-router';
import ProductContainer from '../components/ProductContainer';

const Product: React.FC = () => {

    const {category, id} = useParams<{ category: string; id: string; }>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text="" />
                    </IonButtons>
                    <IonTitle>{category}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <ProductContainer category={category} id={id}/>
            </IonContent>
        </IonPage>
    );
};

export default Product;
