export const categories = [
    {
        name: "Hamburguesas",
        slug: "hamburguesas",
        url: '/Carta/Hamburguesas',
        id: 16,
    },
    {
        name: "Sandwichs",
        slug: "sandwichs",
        url: '/Carta/Sandwichs',
        id: 19,
    },
    {
        name: "Perritos",
        slug: "perritos",
        url: '/Carta/Perritos',
        id: 20,
    },
    {
        name: "Ensaladas",
        slug: "ensaladas",
        url: '/Carta/Ensaladas',
        id: 22,
    },
    {
        name: "Bocatas",
        slug: "bocatas",
        url: '/Carta/Bocatas',
        id: 21,
    },
    {
        name: "Combinados",
        slug: "combinados",
        url: '/Carta/Combinados',
        id: 25,
    },
    {
        name: "Raciones",
        slug: "raciones",
        url: '/Carta/Raciones',
        id: 26,
    },
    {
        name: "Postres",
        slug: "postres",
        url: '/Carta/Postres',
        id: 24,
    },
    {
        name: "Helados",
        slug: "helados",
        url: '/Carta/Helados',
        id: 114,
    },
    {
        name: "Refrescos",
        slug: "refrescos",
        url: '/Carta/Refrescos',
        id: 17,
    }
];
