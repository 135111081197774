import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import React from 'react';
import MenuContainer from "../components/MenuContainer";

const MenuPage: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Menú del día</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <MenuContainer />
            </IonContent>
        </IonPage>
    );
};

export default MenuPage;
