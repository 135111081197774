import React from 'react';
import {IonContent, IonItem, IonLabel, IonList, IonThumbnail, IonSkeletonText} from "@ionic/react";

import {getStoreCategoryProducts, getStoreFeaturedProducts} from '../services/Api'
import {categories} from "../config/categories";
import './CategoryContainer.css';

interface ContainerProps {
    category: string;
}

const CategoryContainer: React.FC<ContainerProps> = ({category}) => {
    const [products, setProducts] = React.useState([]);
    const isFeaturedPage = category === 'Destacados';
    const thisCategory = categories.find(thisCategory => thisCategory.name === category) || {name: '', slug: '', id: 0};

    React.useEffect(() => {
        if (isFeaturedPage) {
            getStoreFeaturedProducts().then((data: React.SetStateAction<never[]>) => setProducts(data));
        } else {
            getStoreCategoryProducts(thisCategory.id).then((data: React.SetStateAction<never[]>) => setProducts(data));
        }
    }, [thisCategory.id, isFeaturedPage]);

    return (
        <IonContent>
            {products.length ? (
                <>
                    <IonList>
                        {
                            products.map(product => {
                                return (
                                    <IonItem disabled={product['stock_status'] === 'outofstock'}
                                             routerLink={"/Carta/" + product['categories'][0]['name'] + "/" + product['id']}
                                             routerDirection="forward"
                                             key={product['id']}>
                                        <IonThumbnail class="ion-margin-end">
                                            <img src={product['images'][0]['src']} alt={product['name']}/>
                                        </IonThumbnail>
                                        <IonLabel>
                                            <h2 className={'ion-text-wrap'}>{product['name']}</h2>
                                            {product['stock_status'] === 'outofstock' ?
                                                <p>Agotado</p>
                                                :
                                                <p>{product['price']}€</p>
                                            }
                                        </IonLabel>
                                    </IonItem>
                                );
                            })
                        }
                    </IonList>
                </>
            ) : (
                <>
                    <IonList>
                        {
                            Array.from(Array(8).keys()).map(i => {
                                return (
                                    <IonItem key={i}>
                                        <IonThumbnail class="ion-margin-end">
                                            <IonSkeletonText animated/>
                                        </IonThumbnail>
                                        <IonLabel>
                                            <h2><IonSkeletonText animated style={{width: '50%', height: '20px'}}/></h2>
                                            <p><IonSkeletonText animated style={{width: '10%'}}/></p>
                                        </IonLabel>
                                    </IonItem>
                                );
                            })
                        }
                    </IonList>
                </>
            )}
        </IonContent>
    );
};

export default CategoryContainer;
