import React from 'react';
import {
    IonContent,
    IonLabel,
    IonItem,
    IonList,
    IonCard,
    IonCardContent,
    IonNote,
    IonItemGroup,
    IonItemDivider,
    IonIcon,
} from "@ionic/react";

import {getMenuDaily} from '../services/Api'
import './MenuContainer.css';
import {handLeftOutline} from "ionicons/icons";

interface ContainerProps {
    category: string;
}

const Capitalize = (str: string) => {
    return (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());
}

const MenuContainer: React.FC = () => {
    const [menu, setMenu] = React.useState({
        error: true,
        menu: [
            {
                postres: {
                    pos_arroz: false,
                    pos_tqueso: false,
                    pos_choco: false,
                    pos_flan: false,
                    pos_mousse: false,
                    pos_tmanzana: false,
                    pos_helado: false,
                    pos_fruta: false,
                    pos_natillas: false
                },
                segundos: {
                    seg_comp_tres: "",
                    seg_comp_dos: "",
                    seg_comp_uno: "",
                    seg_tres: "",
                    seg_uno: "",
                    seg_dos: "",
                },
                primeros: {
                    pri_comp_tres: "",
                    pri_comp_dos: "",
                    pri_comp_uno: "",
                    pri_opc3: "",
                    pri_opc2: "",
                    pri_opc1: "",
                    pri_tres: "",
                    pri_uno: "",
                    pri_dos: ""
                },
                cause: null,
                special: false,
                precio: "",
                fecha: "",
            }]
    });

    React.useEffect(() => {
        getMenuDaily().then((data: React.SetStateAction<{
            error: boolean;
            menu: any[];
        }>) => setMenu(data));
    }, []);

    return (
        <IonContent fullscreen>
            {
                (!menu.error ? menu.menu.map(m => {
                    return (
                        <>
                            <IonCard>
                                <IonCardContent>
                                    <IonList>
                                        <IonItemDivider>
                                            <IonLabel>Primeros</IonLabel>
                                        </IonItemDivider>
                                        <IonItemGroup>
                                            <IonItem>
                                                <IonLabel>{Capitalize(m.primeros.pri_uno)}</IonLabel>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel>{Capitalize(m.primeros.pri_dos)}</IonLabel>
                                            </IonItem>
                                            {!m.primeros.pri_tres.includes("XXX") ?
                                            <IonItem>
                                                <IonLabel>{Capitalize(m.primeros.pri_tres)}</IonLabel>
                                            </IonItem> : null}
                                        </IonItemGroup>
                                        <IonItemDivider>
                                            <IonLabel>Segundos</IonLabel>
                                        </IonItemDivider>
                                        <IonItemGroup>
                                            <IonItem>
                                                <IonLabel>{Capitalize(m.segundos.seg_uno)}</IonLabel>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel>{Capitalize(m.segundos.seg_dos)}</IonLabel>
                                            </IonItem>
                                            {!m.segundos.seg_tres.includes("XXX") ?
                                                <IonItem>
                                                    <IonLabel>{Capitalize(m.segundos.seg_tres)}</IonLabel>
                                                </IonItem> : null}
                                        </IonItemGroup>
                                        <IonItemDivider>
                                            <IonLabel>Postres</IonLabel>
                                        </IonItemDivider>
                                        <IonItemGroup>
                                            {m.postres.pos_tqueso ?
                                                <IonItem><IonLabel>Tarta de queso</IonLabel></IonItem> : null}
                                            {m.postres.pos_arroz ?
                                                <IonItem><IonLabel>Arroz con leche</IonLabel></IonItem> : null}
                                            {m.postres.pos_choco ?
                                                <IonItem><IonLabel>Crema de chocolate</IonLabel></IonItem> : null}
                                            {m.postres.pos_mousse ?
                                                <IonItem><IonLabel>Mousse de limón</IonLabel></IonItem> : null}
                                            {m.postres.pos_flan ?
                                                <IonItem><IonLabel>Flan de huevo</IonLabel></IonItem> : null}
                                            {m.postres.pos_natillas ?
                                                <IonItem><IonLabel>Natillas</IonLabel></IonItem> : null}
                                            {m.postres.pos_tmanzana ?
                                                <IonItem><IonLabel>Tarta de manzana</IonLabel></IonItem> : null}
                                            {m.postres.pos_helado ?
                                                <IonItem><IonLabel>Helado</IonLabel></IonItem> : null}
                                            {m.postres.pos_fruta ?
                                                <IonItem><IonLabel>Fruta del tiempo</IonLabel></IonItem> : null}
                                        </IonItemGroup>
                                    </IonList>

                                </IonCardContent>
                            </IonCard>
                            <IonCard>
                                <IonCardContent class={'ion-text-center'}>
                                    <IonLabel><h2>Precio: {m.precio}€</h2></IonLabel>
                                    <IonNote>
                                        <p>El menú incluye bebida (vino de la casa, gaseosa, agua), pan y café</p>
                                        <p>Suplemento 1€ por consumición en terraza</p>
                                    </IonNote>
                                </IonCardContent>
                            </IonCard>
                        </>
                    );
                }) :
                    <div className="container">
                        <IonIcon icon={handLeftOutline} size={'large'}></IonIcon>
                        <p><strong>Ooops.</strong></p>
                        <p>En este momento no hay ningún menú del día disponible.</p>
                    </div>
                )
            }
        </IonContent>
    );
};

export default MenuContainer;
/*


{
    "error":false,
    "menu":[
    {
        "postres":{
            "pos_arroz":true,
            "pos_tqueso":true,
            "pos_choco":true,
            "pos_flan":false,
            "pos_mousse":true,
            "pos_tmanzana":false,
            "pos_helado":true,
            "pos_fruta":true,
            "pos_natillas":true
        },
        "segundos":{
            "seg_comp_tres":null,
            "seg_comp_dos":null,
            "seg_comp_uno":null,
            "seg_tres":"XXXXXXX",
            "seg_uno":"JAMONCITO DE POLLO AL VINO CON CHAMPIÑONES",
            "seg_dos":"BOQUERONES FRITOS AL AJILLO"
        },
        "primeros":{
            "pri_comp_tres":null,
            "pri_comp_dos":null,
            "pri_comp_uno":null,
            "pri_opc3":"ENSALADA DE LA CASA",
            "pri_opc2":"PASTA DEL DÍA",
            "pri_opc1":"ESPÁRRAGOS DOS SALSAS",
            "pri_tres":"XXXXXX",
            "pri_uno":"SALPICÓN DE PULPO",
            "pri_dos":"CANELONES DE TERNERA , MANZANA Y FOIE"
        },
        "cause":null,
        "special":false,
        "precio":"11",
        "_id":"5ec7b1798e53e51a3d9a8d6a",
        "timestamp":"2020-05-22T11:03:21.353Z",
        "fecha":"viernes, 22 de mayo de 2020",
        "__v":0
    }
]
}*/
