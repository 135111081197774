import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import CategoryContainer from '../components/CategoryContainer';

const Featured: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton className={'animate__animated animate__headShake animate__repeat-3 animate__delay-3s'} />
                    </IonButtons>
                    <IonTitle>Destacados</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <CategoryContainer category={'Destacados'} key={'Destacados'}/>
            </IonContent>
        </IonPage>
    );
};

export default Featured;
