import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import React from 'react';
import {useParams} from 'react-router';
import CategoryContainer from '../components/CategoryContainer';

const Category: React.FC = () => {

    const {category} = useParams<{ category: string; }>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{category}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <CategoryContainer category={category} key={category}/>
            </IonContent>
        </IonPage>
    );
};

export default Category;
